import React from "react"

import PageLayout from "../components/PageLayout"
import SEO from "../components/seo"

import ogImage from "images/seo/home.jpeg"

import RegisterDeal from "../views/register-deal"

const RegisterDealPage = () => (
  <div>
    <SEO
      title="Register a Deal"
      description="Existing Partners: Register a joint customer opportunity with us. Or apply now to become a partner."
      image={ogImage}
    />
    <PageLayout options={{ headingBackground: false }}>
      <RegisterDeal />
    </PageLayout>
  </div>
)

export default RegisterDealPage
