import { css } from "@emotion/core"
import form from "./hubspot-form-base"

const formColors = {
  baseColor: "#3a3a3a",
  labelColor: "#3a3a3a",
  inputColor: "black",
  inputBgColor: "rgba(94, 93, 93, 0.1)",
  buttonBoxShadowColor: "#8c8b8b",
  inputBorderColor: "rgba(0, 0, 0, 0.18)",
}

// All theme-specifc color information and form overrides go here
const formLight = [
  form,
  css`
    form {
      margin-bottom: 0;
    }
    .hs-form-field {
      margin-bottom: 23px;
    }
    .hs-form-wrapper {
      color: ${formColors.baseColor};
    }

    .hs-form-field
      label:not(.hs-error-msg):not(.hs-form-radio-display):not(.hs-form-checkbox-display):not(.hs-form-booleancheckbox-display) {
      color: ${formColors.labelColor};
    }
    .hs-form-field input[type="text"],
    .hs-form-field input[type="email"],
    .hs-form-field input[type="tel"],
    .hs-form-field input[type="number"],
    .hs-form-field textarea {
      background-color: ${formColors.inputBgColor};
      border: 1px solid ${formColors.inputBorderColor};
      color: ${formColors.inputColor};
      &::placeholder {
        color: ${formColors.inputColor};
      }
    }

    .hs-form-field select {
      background-color: ${formColors.inputBgColor};
      border: 1px solid ${formColors.inputBorderColor};
      color: ${formColors.inputColor};
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' x='0px' y='0px' viewBox='0 0 100 69.08875' enable-background='new 0 0 100 55.271' xml:space='preserve'%3E%3Cpath fill='${formColors.inputColor}' d='M50,55.271c-1.349,0-2.697-0.515-3.727-1.544L1.544,8.998c-2.059-2.059-2.059-5.395,0-7.454 s5.395-2.059,7.454,0L50,42.546L91.002,1.544c2.059-2.059,5.395-2.059,7.454,0c2.059,2.059,2.059,5.395,0,7.454L53.727,53.727 C52.697,54.757,51.349,55.271,50,55.271z'/%3E%3C/svg%3E");
    }

    .hs-button {
      &:hover {
        box-shadow: 3px 3px 15px 0 ${formColors.buttonBoxShadowColor};
      }
    }
    h1 {
      color: ${formColors.inputColor};
    }
  `,
]

export default formLight
