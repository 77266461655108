import { useState, useEffect } from "react"
import useScript from "./use-script"

export default function useHubspotForm({
  portalId = "7870773",
  formId,
  target,
  onFormSubmitted,
  onFormSubmit,
  onFormReady,
}) {
  const [appended, setAppended] = useState(false)
  const [loaded, error] = useScript("//js.hsforms.net/forms/v2.js")
  useEffect(() => {
    if (appended) {
      return
    }
    if (loaded && window.hbspt) {
      window.hbspt.forms.create({
        portalId,
        formId,
        target,
        onFormSubmit,
        onFormReady,
      })
      setAppended(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portalId, formId, target, loaded, appended])
  useEffect(() => {
    if (!onFormSubmitted) {
      return
    }
    const cb = (event) => {
      if (
        event.data.type === "hsFormCallback" &&
        event.data.eventName === "onFormSubmitted"
      ) {
        onFormSubmitted()
      }
    }
    window.addEventListener("message", cb)
    return () => window.removeEventListener("message", cb)
  })
  useEffect(() => {
    window.jQuery =
      window.jQuery ||
      (() => ({
        // these are all methods required by HubSpot
        change: () => {},
        trigger: () => {},
      }))
  }, [])
  return { error }
}
