import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"

import { routes } from "src/constants"
import { h2 } from "css/primitives"
import { colors } from "css/theme"

const wrapper = css`
  width: 50%;
  padding-left: 3rem;
  padding-top: 3rem;
  @media (max-width: 1205px) {
    padding-left: 0;
    max-width: 600px;
    width: 100%;
    padding-left: 1rem;
  }
  @media (max-width: 599px) {
    padding-top: 1.5rem;
  }
`

const heading = [
  h2,
  css`
    font-size: 28px;
    line-height: 2;
    margin-bottom: 0;
  `,
]

const description = css`
  color: #919d9f;
  font-size: 16px;
  line-height: 1.55;
  margin-bottom: 20px;
  @media (max-width: 767px) {
    font-size: 1rem;
  }
  a {
    color: ${colors.skyblue};
  }
`

function Info() {
  return (
    <div css={wrapper}>
      <h2 css={heading}>For Channel Partners</h2>
      <p css={description}>
        All deals are registered for 90 days.
        <br />
        After 90 days, deals may be renewed with additional information or will
        expire.
        <br />
        <br />
        <strong>Note:</strong> Please make sure you enter the date of your first
        meeting and target close date.
      </p>
      <h2 css={heading}>Become a Partner</h2>
      <p css={description}>
        Not a current partner? <Link to={routes.contact}>Apply here</Link>
      </p>
    </div>
  )
}

export default Info
