import { css } from "@emotion/core"
import { colors, radius } from "./theme"

// Put all color info in either:
// 1. hubspot-form-light, or
// 2. hubspot-form-dark
// if it is different for each theme
const form = css`
  .hs-form-field {
    margin-bottom: 39px;
  }

  .hs-form-iframe {
    margin-bottom: 0;
  }

  .hs-fieldtype-booleancheckbox.hs-form-field {
    margin-bottom: 23px;
  }

  .actions {
    margin-top: -9px;
  }

  .hs-form-field
    label:not(.hs-error-msg):not(.hs-form-radio-display):not(.hs-form-checkbox-display):not(.hs-form-booleancheckbox-display) {
    font-size: 12px;
    letter-spacing: 0.67px;
    text-transform: uppercase;
    font-family: var(--heading);
    display: block;
    margin-bottom: 0.75rem;
  }
  .hs-form-field input[type="text"],
  .hs-form-field input[type="email"],
  .hs-form-field input[type="tel"],
  .hs-form-field input[type="number"],
  .hs-form-field textarea {
    width: 100%;
    display: block;
    border: 0;
    box-shadow: none;
    border-radius: ${radius.default};
    padding: 13px 17px;
    &::placeholder {
      opacity: 0.5;
    }
    &:focus {
      outline: none;
      box-shadow: 1px 0 5px 0 ${colors.brightBlue},
        0 0 1px 0 inset ${colors.brightBlue};
    }
  }
  .hs-form-field textarea {
    min-height: 6rem;
  }

  .hs-form-field select {
    appearance: none;
    border: 0;
    box-shadow: none;
    border-radius: ${radius.default};
    padding: 13px 17px;
    position: relative;
    background-repeat: no-repeat;
    background-position: right 8px center;
    background-size: 10px;
    width: 100%;
    &::-ms-expand {
      display: none; /* Hide the default arrow in Internet Explorer 10 and Internet Explorer 11 */
    }
    &:focus {
      outline: none;
    }
  }

  .hs-input[type="checkbox"] {
    margin-right: 0.6rem;
  }
  .hs-form-radio {
    list-style: none;
    span {
      margin-left: 0.5rem;
    }
  }

  .inputs-list {
    margin-left: 0;
    margin-top: 1rem;
    margin-bottom: 0;
  }

  .inputs-list.multi-container {
    .hs-input[type="checkbox"] + span {
      top: -2px;
      position: relative;
    }
  }

  .hs-form-booleancheckbox,
  .hs-form-checkbox {
    list-style: none;
    display: flex;
    label span {
      text-transform: none;
    }
  }

  .hs-form-booleancheckbox input {
    position: relative;
    top: 2px;
  }

  .hs-field-desc {
    font-size: 14px;
    margin-bottom: 0.5rem;
    margin-top: -0.5rem;
  }

  .hs-button {
    display: block;
    background-color: ${colors.orange};
    color: white;
    font-family: var(--heading);
    font-size: 14px;
    font-weight: 600;
    line-height: 32px;
    padding: 10px 3rem;
    border-radius: 4px;
    box-shadow: none;
    text-decoration: none;
    text-align: center;
    border: 0;
    cursor: pointer;
    text-transform: uppercase;
    @media (max-width: 479px) {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
    &:hover {
      text-decoration: none;
    }
    width: 100%;
    transform: box-shadow 0.3s ease;
  }

  .hs-error-msgs {
    padding-left: 0;
    margin-left: 0;
  }

  .hs-error-msgs li {
    margin-top: 6px;
    font-size: 14px;
    list-style: none;
    color: ${colors.error};
  }
  h1 {
    font-size: 22px;
    margin-bottom: 0.75rem;
  }
  .grecaptcha-badge {
    box-shadow: none !important;
  }
`

export default form
